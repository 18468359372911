<template>
  <v-card class="elevation-0">
    <v-radio-group
      v-model="chooseAction"
      class="ma-0 pa-0"
      hide-details
      mandatory>
      <v-radio
        :value="0"
        label="With collections"
        @click="selectVersion(null)" />
      <v-radio
        v-if="listOfVersions.length"
        :value="1"
        label="With Issuance"
        @click="selectVersion(null)" />
    </v-radio-group>

    <v-divider class="my-2" />

    <!-- list of versions -->
    <v-card-text
      v-if="chooseAction"
      class="pa-0"
      style="max-height: 300px; overflow-y: auto">
      <v-list class="nav-card">
        <v-list-item
          v-for="(item) in arrayOfVersionsAndProduct"
          :key="item.id"
          :class="{ 'selected': setSelectedClass(item.id), 'compare-disabled': !allowVersionCompareWith(item) }"
          :disabled="!allowVersionCompareWith(item)"
          class="px-2 break-word"
          ripple
          @click="selectVersion(item.id)">
          <span v-if="currentVersion !== item.id || currentVersion !== DEFAULT_PROJECT_VERSION">
            <img
              v-if="item.status === ARCHIVED"
              class="mr-1"
              width="12"
              height="12"
              src="@/assets/icons/archive-icon.svg">
            {{ !chooseAction ? item.name : item.title }}
          </span>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions class="justify-center">
      <app-btn
        v-if="getChooseBtnParams.text"
        :access="getChooseBtnParams.access"
        btn-class="white--text"
        color="blue"
        block
        bottom
        @click.stop="getChooseBtnParams.event">
        {{ getChooseBtnParams.text }}
      </app-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import ProjectVersionsApi from '@/services/graphql/projectVersions';
import { accessHelper } from '@/utils';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
import {
  ARCHIVED, MODIFIED,
} from '@/constants';
export default {
  name: 'ChooseVersions',
  data() {
    return {
      menu: false,
      versionName: null,
      selectedVersion: null,
      chooseAction: 0,
      DEFAULT_PROJECT_VERSION,
      ARCHIVED,
    };
  },
  computed: {
    ...mapState(['role']),
    ...mapState('ProjectVersions', ['listOfVersions']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      currentVersion: 'ProjectVersions/getCurrentVersion',
      canCompareWithCollection: 'UserRoles/canCompareWithCollection',
    }),
    ...mapGetters(['userId']),
    getChooseBtnParams() {
      if (!this.versionName && this.selectedVersion) {
        if (this.chooseAction) {
          return {
            text: 'Continue',
            event: this.selectCompareVersion,
            type: 'issuance',
            disabled: !this.userId,
            access: accessHelper(true),
          };
        }
      }
      if (!this.chooseAction) {
        return {
          //
          text: 'Compare',
          event: this.compareWithProduct,
          disabled: !this.canCompareWithCollection(this.role).allowed,
          access: this.canCompareWithCollection(this.role),
          type: 'collections',
        };
      }
      return {
        text: '',
        disabled: false,
        access: accessHelper(true),
        event: () => {},
      };
    },
    versions: {
      get() {
        const newVersions = [{
          title: 'Live version',
          id: DEFAULT_PROJECT_VERSION,
        }, ...this.listOfVersions];
        const version = newVersions.filter(version => version.id !== this.currentVersion);
        return version;
      },
      set(val) {
        this.setListOfVersions(val);
      },
    },
    arrayOfVersionsAndProduct() {
      return this.versions;
    },
  },
  methods: {
    ...mapActions(['handleError']),
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapMutations('ProjectVersions', ['setListOfVersions']),
    ...mapMutations('ScheduleCompare', ['setCompareVersion', 'setCompareColumns', 'setCompareReference']),
    ...mapActions('ScheduleCompare', ['setAllItemsForCompare']),
    setSelectedClass(id) {
      const { selectedVersion, currentVersion } = this;
      return id === (selectedVersion || currentVersion);
    },
    selectVersion(id) {
      this.selectedVersion = id;
      this.versionName = null;
    },
    compareWithProduct() {
      if (this.currentVersion === DEFAULT_PROJECT_VERSION) {
        this.compare();
      } else {
        this.openSnackBar({
          text: 'You need to be on Live Version to compare',
          timeout: 2000,
        });
      }
    },
    async compare() {
      this.spinner(true);
      const fieldToCompare = [];
      try {
        const { data } = await ProjectVersionsApi.compareScheduleWithReferences({
          projectId: this.$route.params.id,
          scheduleId: this.scheduleId,
          workspaceId: this.activeWorkspaceId,
        });
        data.compareScheduleWithReferences.forEach(el => {
          const fieldsModified = [];
          if (el.status === MODIFIED) {
            el.fields.forEach(field => {
              fieldToCompare.push(field.name);
              fieldsModified.push(field.name);
              el.modified = fieldsModified;
            });
          }
        });
        this.setCompareReference('COMPARE_REFERENCE');
        this.setAllItemsForCompare(data.compareScheduleWithReferences);
        this.setCompareColumns(fieldToCompare);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.$emit('closeMenu', false);
        this.spinner(false);
      }
    },
    selectCompareVersion() {
      this.setCompareVersion(this.selectedVersion);
      this.$emit('changeModal', false);
    },

    /**
     * Prevent comparing with older versions
     * - The 'versions list' SEEMS to be sorted, but to be safe, check 'createdDate'
     * - Fallback, if that doesn't exist.
     */
    allowVersionCompareWith(version) {
      if (!version) return false;

      // live version
      if (this.currentVersion === DEFAULT_PROJECT_VERSION) {
        return true;
      }
      const currVersion = this.listOfVersions.find(v => v.id === this.currentVersion);
      if (!currVersion) {
        console.warn('Couldn\'t find current version in versions list! Defaulting to allow compare');
        return true;
      }

      const currVersionTime = new Date(currVersion.createdAt);
      const compVersionTime = new Date(version.createdAt);

      if (isNaN(currVersionTime) || isNaN(compVersionTime)) {
        // Resort to position in versions list if we have nonsense time. Earlier items are first.
        const indexCurr = this.listOfVersions.findIndex(v => v.id === currVersion);
        const indexComp = this.listOfVersions.findIndex(v => v.id === version.id);
        return indexCurr > indexComp;
      } else {
        return currVersionTime > compVersionTime;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  p {
    margin-bottom: 0;
  }
  ::v-deep.v-card {
    padding: 16px;
    input {
      margin-left: 0;
    }
    .v-text-field.edit-input {
      height: 30px;
      .v-input__control {
        padding: 0;
        height: 27px;
        margin-top: 0;
        .v-text-field__slot {
          padding-left: 0;
          height: 27px;
        }
      }
      padding-left: 0;
      padding-top: 0;
      input {
        padding: 0 !important;
      }
    }
  }
  ::v-deep .continueButton {
    background: #085C71!important;
    border-radius: 5px!important;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135.69%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .compare-disabled {
    pointer-events: none;
    p {
      color: #BBBBBB !important;
    }
  }
</style>
