<template>
  <div>
    <div class="px-4 mb-4">
      <app-btn
        btn-class="white--text"
        color="blue"
        bottom
        :disabled="!hasComparison"
        @click="clearComparison">
        Clear Comparison
      </app-btn>
    </div>

    <ChooseVersions
      v-if="changeMenu"
      @closeMenu="closeMenu"
      @changeModal="changeModal" />
    <ChooseColumns
      v-else
      :menu="menu"
      @closeMenu="closeMenu"
      @changeModal="changeModal" />
  </div>
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { NOT_PERMITTED_ACCESS } from '@/constants/userPermissions';
import ChooseVersions from './ChooseVersions';
import ChooseColumns from './ChooseColumns';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
export default {
  name: 'ProjectDetailsCompareViews',
  components: {
    ChooseVersions,
    ChooseColumns,
  },
  data() {
    return {
      menu: false,
      changeMenu: true,
      NOT_PERMITTED_ACCESS,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('ScheduleCompare', [
      'compareVersionLength',
      'compareListWithVersionsLength',
    ]),
    ...mapState('ScheduleCompare', [
      'compareVersion',
      'compareColumns',
    ]),
    ...mapState('ProjectVersions', ['listOfVersions']),
    showTitle() {
      let name = 'Compare';
      if (this.compareVersion === DEFAULT_PROJECT_VERSION) {
        return 'Live version';
      } else if (this.compareVersion) {
        this.listOfVersions.filter(element =>
          element.id === this.compareVersion ? name = element.title : '');
        return name;
      } else {
        return name;
      }
    },
    hasComparison() {
      return this.compareVersion?.length > 0;
    },
  },
  watch: {
    menu() {
      if (!this.compareVersionLength) {
        this.changeMenu = true;
      } else if (this.compareVersionLength && this.compareListWithVersionsLength) {
        this.changeMenu = false;
      } else if (!this.menu && this.compareColumns.length === 0) {
        this.resetState();
        this.changeMenu = true;
      } else if (!this.menu && this.compareVersionLength) {
        this.changeMenu = false;
      }
    },
  },
  methods: {
    ...mapMutations('ScheduleCompare', ['resetState']),
    changeModal(item) {
      this.changeMenu = item;
    },
    closeMenu(modalState) {
      this.menu = modalState;
    },
    clearComparison() {
      this.resetState();
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-menu__content.edit-menu {
    box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  }
  .buttonText {
    width: 100px!important;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .buttons-wrapper .v-btn {
    max-width: 115px;
  }
  .buttons-wrapper .v-icon {
    margin-right: 5px!important;
  }
</style>
